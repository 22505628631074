import React, { cloneElement, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import AccessTime from "@material-ui/icons/AccessTime";
import DevicesIcon from "@material-ui/icons/Devices";
import { useFormState } from "react-final-form";
import {
  Datagrid,
  Edit,
  List,
  Filter,
  Toolbar,
  TabbedForm,
  FormTab,
  BooleanInput,
  TextField,
  TextInput,
  SearchInput,
  SaveButton,
  useTranslate,
  Pagination,
  TopToolbar,
  sanitizeListRestProps,
  useNotify,
  DateField,
  useQuery,
  Loading,
  useRecordContext,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import dataIntermediaryProvider from "../synapse/intermediaryDataProvider";
import SilentNotifications from "./custom/layout/SilentNotifications";
import { capitalizeWords } from "../mixins/utils";
import "../assets/styles/UserPagination.css";
import {ColorBooleanField} from "./userRolesPermissions";

const useStyles = makeStyles(() => ({
  small: {
    height: "40px",
    width: "40px",
  },
  large: {
    height: "120px",
    width: "120px",
    float: "right",
  },
  capitalized: () => ({
    textTransform: "capitalize",
  }),
  center: () => ({
    width: "fit-content",
    height: "fit-content",
    position: "absolute",
    zIndex: "99",
    top: "50%",
    left: "50%",
  }),
  visible: () => ({
    width: "100%",
  }),
  blurred: () => ({
    width: "100%",
    filter: "blur(10px)",
  }),
  column: () => ({
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
  }),
  greenStyle: () => ({
    color: "green",
  }),
  redStyle: () => ({
    color: "red",
  }),
}));

const UserListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
    </TopToolbar>
  );
};

UserListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export const UserPagination = props => {
  const t = useTranslate();

  return (
    <Pagination
      {...props}
      rowsPerPageOptions={[
        { value: 10, label: "10" },
        { value: 25, label: "25" },
        { value: 50, label: "50" },
        { value: 100, label: "100" },
        { value: 500, label: "500" },
        { value: 1000, label: "1000" },
        { value: 100000, label: t("resources.users.show_all") }, // You can customize the label for "Show All"
      ]}
    />
  );
};

const UserFilter = props => {
  const t = useTranslate();

  return (
    <Filter {...props}>
      <SearchInput
        source="name"
        alwaysOn
        placeholder={t("resources.users.search_placeholder")}
      />
    </Filter>
  );
};

const AvatarField = ({ source, className, record = {} }) => (
  <Avatar src={record[source]} className={className} />
);

export const CustomTextField = ({ source, property }) => {
  const record = useRecordContext();
  return (
    <span style={{ color: record[property] && "grey" }}>
      {record && capitalizeWords(record[source]) }
    </span>
  );
};


export const UserList = props => {
  const classes = useStyles();
  const t = useTranslate();
  return (
    <List
      {...props}
      filters={<UserFilter/>}
      sort={{ field: "name", order: "ASC" }}
      actions={<UserListActions maxResults={10000} />}
      bulkActionButtons={false}
      pagination={<UserPagination />}
    >
      <Datagrid rowClick="edit">
        <CustomTextField
          property="deactivated"
          source="username"
          sortBy="name"
        />
        <CustomTextField
          property="deactivated"
          label={t("resources.users.fields.first_name")}
          source="first_name"
          sortBy="displayname"
          className={classes.capitalized}
        />
        <CustomTextField
          property="deactivated"
          label={t("resources.users.fields.last_name")}
          source="last_name"
          sortBy="last_name"
          className={classes.capitalized}
        />
        <CustomTextField
          property="deactivated"
          source="role"
          sortBy="role"
          className={classes.capitalized}
        />
        <CustomTextField
          property="deactivated"
          source="displayname"
          sortBy="name"
          className={classes.capitalized}
        />
        <ColorBooleanField
          source="mebis_deactivated"
          label={t("resources.users.fields.deactivated")}
          sortBy="deactivated"
          property={'deactivated'}
        />
      </Datagrid>
    </List>
  );
};

export function generateRandomUser() {
  const homeserver = localStorage.getItem("home_server");
  const user_id =
    "@" +
    Array(8)
      .fill("0123456789abcdefghijklmnopqrstuvwxyz")
      .map(
        x =>
          x[
            Math.floor(
              (crypto.getRandomValues(new Uint32Array(1))[0] /
                (0xffffffff + 1)) *
                x.length
            )
          ]
      )
      .join("") +
    ":" +
    homeserver;

  const password = Array(20)
    .fill(
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$"
    )
    .map(
      x =>
        x[
          Math.floor(
            (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) *
              x.length
          )
        ]
    )
    .join("");

  return {
    id: user_id,
    password: password,
  };
}

const updateUser = (
  id,
  isAdmin,
  isDeactivated,
  permissionsList,
  notify,
  lifter
) => {
  /*
   * id => number
   * isAdmin => boolean
   * isDeactivated => boolean
   * permissionsList => {permission: boolean}
   * notify => function useNotify hook
   * */
  lifter(true);
  dataIntermediaryProvider
    .updateUser(
      {
        admin: isAdmin,
        deactivated: isDeactivated,
        permissions: permissionsList,
      },
      id,'success_msg_deactivation'
    )
    .then(() => {
      lifter(false);
    })
    .catch(() => {
      lifter(false);
    });
};
const updateSilentTimes = (id, payload, notify, lifter) => {
  lifter(true);
  dataIntermediaryProvider
    .updateSingleSN(payload, id[0])
    .then(() => {
      lifter(false);
    })
    .catch(() => {
      lifter(false);
    });
};

const UserEditToolbar = props => {
  const notify = useNotify();
  const id = props.record.id;
  const { values } = useFormState();
  const isAdmin = values.admin;
  const isDeactivated = values.deactivated;
  const permissionsList = values.permission;
  const currentTab = window.location.hash.split("/");

  const setUpdateFunction = tab => {
    if (tab === "1") {
      return updateSilentTimes([id], props.snPayload, notify, props.lifter);
    }
    return updateUser(
      id,
      isAdmin,
      isDeactivated,
      permissionsList,
      notify,
      props.lifter
    );
  };

  return (
    <Toolbar {...props}>
      <SaveButton
        onSave={() => {
          setUpdateFunction(currentTab[currentTab.length - 1]);
        }}
        disabled={props.isSnError || props.isSaveDisabled}
        submitOnEnter={true}
      />
    </Toolbar>
  );
};

const UserTitle = ({ record }) => {
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.users.name", {
        smart_count: 1,
      })}{" "}
      {record ? `"${record.displayname}"` : ""}
    </span>
  );
};
export const ROLES = {
  teacher: "LEHRER",
};
export const UserEdit = props => {
  const classes = useStyles();
  const translate = useTranslate();
  const [payload, setPayload] = useState({});
  const [isSnError, setIsSnError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const setSNPayload = (payload, snError) => {
    setIsSnError(snError);
    setPayload(payload);
  };
  const date_format = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };
  const userId = props.id;
  const isTeacher = role => role?.toString().toUpperCase() === ROLES.teacher;
  const { data: userData } = useQuery({
    type: "getOne",
    resource: "users",
    payload: { id: userId },
  });
  const userRole = userData?.data?.role_slug;
  const isDevicesTabOpen = window.location.hash.split("/")[3] === "2";

  const handleLoadingStatus = val => {
    setIsLoading(val);
  };
  return (
    <Edit {...props} title={<UserTitle />}>
      <TabbedForm
        toolbar={
          <UserEditToolbar
            snPayload={payload}
            isSnError={isSnError}
            isSaveDisabled={!!isDevicesTabOpen}
            lifter={handleLoadingStatus}
          />
        }
      >
        <FormTab
          label={translate("resources.users.name", { smart_count: 1 })}
          icon={<PersonPinIcon />}
        >
          {isLoading && (
            <Loading
              loadingPrimary=""
              loadingSecondary=""
              className={classes.center}
            />
          )}
          <div className={isLoading ? classes.blurred : classes.visible}>
            <div className={classes.column}>
              <TextInput
                source="username"
                disabled
                label={translate("resources.users.fields.uid")}
              />
              <TextInput
                source="displayname"
                disabled
                format={capitalizeWords}
              />
              <TextInput source="role" disabled format={capitalizeWords} />
              <BooleanInput
                source="deactivated"
                label={translate("resources.users.fields.deactivated")}
              />
            </div>
          </div>
        </FormTab>
        <FormTab
          label={translate("synapseadmin.users.notifications")}
          icon={<AccessTime />}
        >
          <div className={isLoading ? classes.blurred : classes.visible}>
            {isLoading && (
              <Loading
                loadingPrimary=""
                loadingSecondary=""
                className={classes.center}
              />
            )}
            <SilentNotifications {...props} lifter={setSNPayload} />
          </div>
        </FormTab>
        {isTeacher(userRole) && (
          <FormTab
            label={translate("resources.devices.name", { smart_count: 2 })}
            icon={<DevicesIcon />}
          >
            <List
              style={{ width: "100%" }}
              {...props}
              sort={{ field: "name", order: "ASC" }}
              actions={<UserListActions maxResults={5} />}
              bulkActionButtons={false}
              resource="devices"
              title=" "
            >
              <Datagrid style={{ width: "100%" }}>
                <TextField source="user_id" sortable={false} />
                <TextField source="device_id" sortable={false} />
                <TextField source="display_name" sortable={false} />
                <TextField source="last_seen_user_agent" sortable={false} />
                <TextField source="last_seen_ip" sortable={false} />
                <DateField
                  source="last_seen_ts"
                  showTime
                  options={date_format}
                  sortable={false}
                />
              </Datagrid>
            </List>
          </FormTab>
        )}
      </TabbedForm>
    </Edit>
  );
};
