import {fetchUtils} from "react-admin";
import { stringify } from "query-string";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import de from '../i18n/de';
import en from '../i18n/en';

const baseURl = window.ENV.intermediary_endpoint + "/api";
const permissionEvent = new CustomEvent("permissionsUpdate", {
  detail: "permissionsUpdate event triggered",
});

const checkLanguage = () => {
  const env = window.ENV.intermediary_endpoint
  let language;
  switch (env) {
    case "https://api.staging-bycsmessenger.dev/":
      language = navigator.language;
      break;
    case "https://api.messenger.bycs-int.de":
      language = navigator.language;
      break;
    case "https://api.messenger.bycs-vp.de/":
      language = 'de';
      break;
    default:
      language = "de";
  }
  return language === "de" ? de : en
}


const jsonClient = (url, options = {}) => {
  const token = localStorage.getItem("auth_token_intermediary");
  console.log("httpClient " + url);
  if (token != null) {
    options.user = {
      authenticated: true,
      token: `Bearer ${token}`,
    };
  }
  return fetchUtils.fetchJson(url, options);
};

const resourceMap = {
  roles: {
    path: "/admin/roles",
    data: "roles",
    total: json => json.data,
  },
  groups: {
    path: "/admin/groups",
    data: "groups",
    total: json => json.data,
  },
  devices: {
    path: "/admin/users/",
    data: "devices",
    map: u => ({
      id: u?.id,
      user_id: u?.user_id,
      device_id: u?.device_id,
      display_name: u?.display_name,
      last_seen_user_agent: u?.last_seen_user_agent,
      last_seen_ts: u?.last_seen_ts,
      last_seen_ip: u?.last_seen_ip,
    }),
    total: json => json.data,
  },
  users: {
    path: "/admin/users",
    map: u => ({
      ...u,
      id: u.id,
      uid: u.uid,
      displayname: `${u["first_name"]} ${u["last_name"]}`,
      first_name: u["first_name"],
      last_name: u["last_name"],
      avatar_src: u.avatar,
      role: u?.role_slug,
      admin: !!u["admin_staff"],
      deactivated: !!u["mebis_deactivated"],
      // need timestamp in milliseconds
      creation_ts_ms: u["create_date"] * 1000,
    }),
    data: "users",
    total: json => json.data,
  },
  roles_permissions: {
    path: "/admin/users",
    map: u => ({
      ...u,
      id: u.id,
      name: `${u["first_name"]} ${u["last_name"]}`,
      userName: u.username,
      add_guest_to_room: u?.permission?.add_guest_to_room,
      attachment_forward: u?.permission?.attachment_forward,
      attachment_send: u?.permission?.attachment_send,
      block_users: u?.permission?.block_users,
      camera_access: u?.permission?.camera_access,
      delete_message: u?.permission?.delete_message,
      edit_message: u?.permission?.edit_message,
      emergency_message: u?.permission?.emergency_message,
      file_access: u?.permission?.file_access,
      gallery_access: u?.permission?.gallery_access,
      invite_guests: u?.permission?.invite_guests,
      location_share: u?.permission?.location_share,
      manage_rooms: u?.permission?.manage_rooms,
      voice_message: u?.permission?.voice_message,
      search_users: u?.permission?.search_users,
      send_message: u?.permission?.send_message,
      video_call: u?.permission?.video_call,
      voice_call: u?.permission?.voice_call,
      // need timestamp in milliseconds
      creation_ts_ms: u["create_date"] * 1000,
    }),
    data: "users",
    total: json => json.data,
  },
};

// TODO refactor dataIntermediaryProvider to remove duplicateCode

const dataIntermediaryProvider = {
  getList: (resource, params) => {
    const { user_id, name, deactivated, search_term } = params.filter;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const fields = {
      avatar_url: "avatar",
      name: "username",
      uid: "uid",
      last_name: "last_name",
      role: "role_slug",
      displayname: "first_name",
      admin: "admin_staff",
      deactivated: "mebis_deactivated",
      create_date: "create_date",
      add_guest_to_room: "permission.add_guest_to_room",
      attachment_forward: "permission.attachment_forward",
      attachment_send: "permission.attachment_send",
      block_users: "permission.block_users",
      camera_access: "permission.camera_access",
      delete_message: "permission.delete_message",
      edit_message: "permission.edit_message",
      emergency_message: "permission.emergency_message",
      file_access: "permission.file_access",
      gallery_access: "permission.gallery_access",
      invite_guests: "permission.invite_guests",
      location_share: "permission.location_share",
      manage_rooms: "permission.manage_rooms",
      voice_message: "permission.voice_message",
      search_users: "permission.search_users",
      send_message: "permission.send_message",
      video_call: "permission.video_call",
      voice_call: "permission.voice_call",
    };

    const query = {
      from: page,
      limit: perPage,
      user_id: user_id,
      search_term: search_term,
      name: name,
      mebis_deactivated: deactivated,
      order_by: fields[field],
      dir: order,
    };

    const homeserver = baseURl;
    if (!homeserver || !(resource in resourceMap)) return Promise.reject();

    const res = resourceMap[resource];
    if (res.data === "devices") {
      const userId = window.location.hash.split("/")[2];
      const endpoint_url = homeserver + res.path + `${userId}/devices`;
      return jsonClient(endpoint_url).then(({ json }) => {
        const result = {
          data: json.data,
          total: json.data.length,
        };
        result.data.forEach(r => {
          r.id = uuidv4();
        });
        return result;
      });
    }

    // Adjust sort query params for users route
    if (resource === "users") {
      // sort by role
      if (query.order_by === "role_slug") {
        query.order_by = `${query.order_by} ${query.dir},last_name ASC`;
        delete query.dir;
      }

      // sort deactivated users by role
      if (query.order_by === "mebis_deactivated") {
        query.order_by = `role_slug ASC,${query.order_by} ${query.dir}`;
        delete query.dir;
      }
    }

    // Adjust sort query params for roles_permissions route
    if (resource === "roles_permissions") {
      if (query.order_by.includes("permission")) {
        query.order_by = `mebis_deactivated ASC,role_slug ASC,${query.order_by} ${query.dir}`;
      } else {
        query.order_by = `mebis_deactivated ASC,${query.order_by} ${query.dir}`;
      }
      delete query.dir;
    }

    const endpoint_url = homeserver + res.path;
    const url = `${endpoint_url}?${stringify(query)}`;

    return jsonClient(url).then(({ json }) => ({
      data: json.data.items.map(res.map),
      total: json.data.total,
    }));
  },

  getOne: (resource, params) => {
    const homeserver = baseURl;
    if (!homeserver || !(resource in resourceMap)) return Promise.reject();

    const res = resourceMap[resource];
    const endpoint_url = homeserver + res.path;

    return jsonClient(`${endpoint_url}/${params.id}`).then(({ json }) => ({
      data: res.map(json),
    }));
  },

  getNoTotalList: (resource, params) => {
    const homeserver = baseURl;
    if (!homeserver || !(resource in resourceMap)) return Promise.reject();
    const query = {
      name: params?.name,
      role: params?.role,
    };
    const res = resourceMap[resource];

    const endpoint_url = homeserver + res.path;
    const url = params
      ? `${endpoint_url}?${stringify(query)}`
      : `${endpoint_url}`;
    return jsonClient(url).then(({ json }) => ({
      data: json.data,
    }));
  },

  updatePermissions: (permissionsList, userIdsList) => {
    permissionsList["video_call"] = false;
    permissionsList.attachment_send = false;
    try {
      return fetch(`${baseURl}/admin/users`, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("auth_token_intermediary"),
          "Content-Type": "application/json",
        },
        method: "PATCH",
        body: JSON.stringify({
          users: userIdsList,
          permission: permissionsList,
        }),
      })
        .then(response => response.json())
        .then(() => {
          window.dispatchEvent(permissionEvent);
          toast.success(checkLanguage().resources.users.success_msg_permissions);

        });
    } catch (er) {
      console.error(er);
      toast.warning(checkLanguage().synapseadmin.users.err_msg);

    }
  },

  updateUser: (data, userId, successMsg) => {
    try {
      return fetch(`${baseURl}/admin/users/${userId}`, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("auth_token_intermediary"),
          "Content-Type": "application/json",
        },
        method: "PATCH",
        body: JSON.stringify({
          mebis_deactivated: data?.deactivated,
          admin_staff: data?.admin,
          permission: {
            file_access: data?.permissions?.file_access,
            send_message: data?.permissions?.send_message,
            edit_message: data?.permissions?.edit_message,
            delete_message: data?.permissions?.delete_message,
            gallery_access: data?.permissions?.gallery_access,
            voice_call: data?.permissions?.voice_call,
            video_call: false,
            location_share: data?.permissions?.location_share,
            emergency_message: data?.permissions?.emergency_message,
            attachment_send: data?.permissions?.attachment_send,
            attachment_forward: data?.permissions?.attachment_forward,
            camera_access: data?.permissions?.camera_access,
            voice_message: data?.permissions?.voice_message,
            manage_rooms: data?.permissions?.manage_rooms,
            block_users: data?.permissions?.block_users,
            search_users: data?.permissions?.search_users,
            invite_guests: data?.permissions?.invite_guests,
            add_guest_to_room: data?.permissions?.add_guest_to_room,
          },
        }),
      },successMsg)
        .then(response => response.json())
        .then(() => {
          window.dispatchEvent(permissionEvent);
          toast.success(checkLanguage().resources.users[successMsg]);
        });
    } catch (er) {
      toast.warning(checkLanguage().synapseadmin.users.err_msg)
      console.error(er);
    }
  },

  updateRestrictionActivationData: (data, showSuccessToast=true,successMsg) => {
    const payload = {
      users: data.users,
    };

    if (data.mebisDeactivated !== undefined) {
      payload.mebis_deactivated = data.mebisDeactivated;
    }

    if (data.accessRestricted !== undefined) {
      payload.access_restricted = data.accessRestricted;
    }

    try {
      return fetch(`${baseURl}/admin/users`, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("auth_token_intermediary"),
          "Content-Type": "application/json",
        },
        method: "PATCH",
        body: JSON.stringify(payload),
      }).then(response => response.json()).then(()=>{
       showSuccessToast && toast.success(checkLanguage().resources.users[successMsg])
      });
    } catch (er) {
      console.error(er);
      toast.warning(checkLanguage().resources.users.err_msg)
    }
  },
  updateSingleSN: (data, userId) => {
    try {
      return fetch(`${baseURl}/admin/users/${userId}`, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("auth_token_intermediary"),
          "Content-Type": "application/json",
        },
        method: "PATCH",
        body: JSON.stringify({
          silent_notifications: data,
        }),
      }).then(()=>{
        toast.success(checkLanguage().synapseadmin.users.silent_notifications.success_msg)

      });
    } catch (er) {
      console.error(er);
      toast.warning(checkLanguage().synapseadmin.users.err_msg)
    }
  },
  updateSilentModification: (users, silentNotifications) => {
    if (!silentNotifications.allow_notifications_on_weekends) {
      silentNotifications.allow_notifications_on_weekends = false;
    }

    const payload = {
      users: users,
      silent_notifications: silentNotifications,
    };

    try {
      return fetch(`${baseURl}/admin/users`, {
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("auth_token_intermediary"),
          "Content-Type": "application/json",
        },
        method: "PATCH",
        body: JSON.stringify(payload),
      })
        .then(response => response.json()).then(()=>{
            toast.success(checkLanguage().synapseadmin.users.silent_notifications.success_msg)
          })
        .catch(er => er);
    } catch (er) {
      console.error(er);
      toast.warning(checkLanguage().synapseadmin.users.err_msg)
    }
  },

  updateSchoolLogo: payload => {
    try {
      return fetch(baseURl + "/admin/school/logo", {
        method: "POST",
        body: payload,
        headers: {
          Authorization:
            "Bearer " + localStorage.getItem("auth_token_intermediary"),
        },
      }).then(()=>{
        toast.success(checkLanguage().resources.school_settings.avatar.success_msg)
      });
    } catch (er) {
      console.error(er);
      toast.warning(checkLanguage().resources.school_settings.avatar.err_msg)
    }
  },

  getInitialSchoolLogo: () => {
    return jsonClient(baseURl + "/admin/school").then(({ json }) => ({
      data: json.data,
    }));
  },
};

export default dataIntermediaryProvider;
