import { useState } from "react";
import { useTranslate } from "ra-core";
import AvatarInput from "../form/AvatarInput";
import { makeStyles } from "@material-ui/core/styles";
import { permissionsList } from "../../userRolesPermissions";
import { Create, FormTab, Loading, SaveButton, TabbedForm } from "react-admin";
import * as React from "react";
import CustomBooleanInput from "../form/CustomBooleanInput";
import intermediaryDataProvider from "../../../synapse/intermediaryDataProvider";
import SilentNotifications from "../layout/SilentNotifications";
import dataIntermediaryProvider from "../../../synapse/intermediaryDataProvider";
import SwitchGroup from "../form/SwitchGroup";
import InfoCard from "../layout/InfoCard";
import Modal from "../Modal";
import CustomBooleanModal from "../layout/CustomBooleanModal";

const useStyles = makeStyles(theme => ({
  title: () => ({
    marginTop: "4rem",
    fontSize: theme.typography.h1,
    fontFamily: theme.typography.fontFamily,
  }),
  description: () => ({
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
  }),
  saveBtns: () => ({
    margin: "2rem 0",
  }),
  center: () => ({
    width: "fit-content",
    height: "fit-content",
    position: "absolute",
    zIndex: "99",
    top: "50%",
    left: "50%",
  }),
  visible: () => ({
    width: "100%",
  }),
  blurred: () => ({
    width: "100%",
    filter: "blur(10px)",
  }),
  permissionInfoWrapper: () => ({
    width: "100%",
    marginTop: "1rem",
  }),
  sNInfoWrapper: () => ({
    width: "100%",
    margin: "1rem 0",
  }),
}));

const SettingsToolbar = () => {
  return <></>;
};

const Settings = ({ staticContext, ...props }) => {
  const translate = useTranslate();
  const [lockCodeValue, setLockCodeValue] = useState(false);
  const [deactivationValue, setDeactivationValue] = useState(false);
  const [success, setSuccess] = useState(true);
  const [bulkPermissions, setBulkPermissions] = useState({
    add_guest_to_room: false,
    attachment_forward: false,
    attachment_send: false,
    block_users: false,
    camera_access: false,
    delete_message: false,
    edit_message: false,
    emergency_message: false,
    file_access: false,
    gallery_access: false,
    invite_guests: false,
    location_share: false,
    manage_rooms: false,
    mic_access: false,
    search_users: false,
    send_message: false,
    video_call: false,
    voice_call: false,
  });
  const [payload, setPayload] = useState(null);
  const [isSnError, setIsSnError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
  const [isSNModalOpen, setIsSNModalOpen] = useState(false);
  const [isLockCodeModalOpen, setIsLockCodeModalOpen] = useState(false);
  const [isDeactivationModalOpen, setIsDeactivationModalOpen] = useState(false);
  const [showPermissionInfo, setShowPermissionInfo] = useState(false);
  const [showSNInfo, setShowSNInfo] = useState(false);
  const styles = useStyles();
  const setSNPayload = (payload, snError) => {
    setIsSnError(!!snError);
    setPayload(payload);
  };

  const setLockCodeData = val => {
    setLockCodeValue(val);
  };

  const handleGroupPermissions = pList => {
    const updatedList = {};
    pList.forEach(p => {
      updatedList[p.name] = p.checked;
    });
    setBulkPermissions(updatedList);
  };

  const setDeactivationData = val => {
    setDeactivationValue(val);
  };

  const permissionsInit = () => {
    return (
      permissionsList?.map((p, i) => ({
        id: i,
        name: p,
        checked: true,
        label: translate(`resources.roles_permissions.${p}`),
      })) || []
    );
  };

  const handleDeactivation = async () => {
    setIsLoading(true);
    try {
      await intermediaryDataProvider.updateRestrictionActivationData({
        mebisDeactivated: deactivationValue,
        users: [],
      },true,'success_msg_deactivation');
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handlePermissions = () => {
    setShowPermissionInfo(true);
    const isChangedPermission = () => {
      let hasTrueValue = false;

      for (let p in bulkPermissions) {
        if (bulkPermissions[p] === true) {
          hasTrueValue = true;
          break;
        }
      }
      return hasTrueValue;
    };

    if (isChangedPermission) {
      setIsLoading(true);
      setIsPermissionModalOpen(false);

      dataIntermediaryProvider
        .updatePermissions(bulkPermissions, [])
        .then(() => {
          setIsLoading(false);
          setShowPermissionInfo(false);
        })
        .catch(er => {
          setIsLoading(false);
          setShowPermissionInfo(false);
        });
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleSilentNotifications = () => {
    scrollToTop();
    setShowSNInfo(true);
    setIsLoading(true);
    setIsSNModalOpen(false);

    if (payload) {
      dataIntermediaryProvider
        .updateSilentModification([], payload)
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setSuccess(false);
          setIsLoading(false);
        })
        .finally(() => {
          setShowSNInfo(false);
        });
    }
  };

  const handleRestrictionAccess = async () => {
    await intermediaryDataProvider.updateRestrictionActivationData({
      accessRestricted: lockCodeValue,
    },true,'success_msg_restriction')
  };

  return (
    <Create
      resource="schools"
      basePath="/school-settings"
      redirect={false}
      title={translate("resources.school_settings.name")}
      {...props}
    >
      <TabbedForm
        title={translate("resources.school_settings.name")}
        toolbar={<SettingsToolbar />}
      >
        <FormTab label={translate("resources.school_settings.avatar.input")}>
          <AvatarInput
            source="logo"
            label={translate("resources.school_settings.avatar.input")}
          />
        </FormTab>
        
        {/* Hide this feature for release 3b - https://sdui.atlassian.net/browse/BYCS-1321 */}
        {/* <FormTab
          label={translate(
            "resources.school_settings.access_restriction.label"
          )}
        >
          <CustomBooleanInput
            lifter={setLockCodeData}
            source="forcePassApp"
            sectionTitle={translate(
              "resources.school_settings.access_restriction.label"
            )}
            inputLabel={translate(
              "resources.school_settings.access_restriction.input"
            )}
          />
          <p className={styles.description}>
            {translate(
              "resources.school_settings.access_restriction.description"
            )}
          </p>
          <SaveButton
            handleSubmitWithRedirect={() => setIsLockCodeModalOpen(true)}
            submitOnEnter={true}
            disabled={false}
            className={styles.saveBtns}
          />
          <Modal
            fullWidth
            isOpen={isLockCodeModalOpen}
            onClose={() => {
              setIsLockCodeModalOpen(false);
            }}
          >
            <CustomBooleanModal
              title={translate(
                "resources.school_settings.access_restriction.attention"
              )}
              text={translate(
                "resources.school_settings.access_restriction.modalText"
              )}
              declineBtn={translate(
                "resources.school_settings.access_restriction.no"
              )}
              successBtn={translate(
                "resources.school_settings.access_restriction.yes"
              )}
              onSuccess={async () => {
                setIsLockCodeModalOpen(false)
               await handleRestrictionAccess();
              }}
              onDecline={() => {
                setIsLockCodeModalOpen(false);
              }}
            />
          </Modal>
        </FormTab> */}
        
        <FormTab
          label={translate("resources.school_settings.deactivation.modalTitle")}
        >
          {isLoading && (
            <Loading
              loadingPrimary=""
              loadingSecondary=""
              className={styles.center}
            />
          )}
          <div className={isLoading && styles.blurred}>
            <CustomBooleanInput
              lifter={setDeactivationData}
              source="deactivateSchool"
              inputLabel={translate(
                "resources.school_settings.deactivation.input"
              )}
              sectionTitle={translate(
                "resources.school_settings.deactivation.title"
              )}
            />
            <p className={styles.description}>
              {translate("resources.school_settings.deactivation.description")}
            </p>
            <SaveButton
              handleSubmitWithRedirect={() => setIsDeactivationModalOpen(true)}
              submitOnEnter={true}
              disabled={false}
              className={styles.saveBtns}
            />
          </div>
          <Modal
              fullWidth
              isOpen={isDeactivationModalOpen}
              onClose={() => {
                setIsDeactivationModalOpen(false);
              }}
          >
            <CustomBooleanModal
                title={translate(
                    "resources.school_settings.access_restriction.attention"
                )}
                text={translate(
                    "resources.school_settings.deactivation.modalText"
                )}
                declineBtn={translate(
                    "resources.school_settings.access_restriction.no"
                )}
                successBtn={translate(
                    "resources.school_settings.access_restriction.yes"
                )}
                onSuccess={async () => {
                  setIsDeactivationModalOpen(false)
                  await handleDeactivation();
                }}
                onDecline={() => {
                  setIsDeactivationModalOpen(false);
                }}
            />
          </Modal>
        </FormTab>

        <FormTab label={translate("synapseadmin.users.silent_times")}>
          {showSNInfo && (
            <div className={styles.sNInfoWrapper}>
              <InfoCard
                text={translate("synapseadmin.users.permissions_info")}
                width={"80%"}
              />
            </div>
          )}
          <div className={isLoading ? styles.blurred : styles.visible}>
            <h2 className={styles.title}>
              {translate("synapseadmin.users.notifications")}
            </h2>
            {isLoading && (
              <Loading
                loadingPrimary=""
                loadingSecondary=""
                className={styles.center}
              />
            )}
            <Modal
              fullWidth
              isOpen={isSNModalOpen}
              onClose={() => {
                setIsSNModalOpen(false);
              }}
            >
              <CustomBooleanModal
                title={translate(
                  "resources.school_settings.access_restriction.attention"
                )}
                text={translate(
                  "synapseadmin.users.silent_notifications.warning_msg"
                )}
                declineBtn={translate(
                  "resources.school_settings.access_restriction.no"
                )}
                successBtn={translate(
                  "resources.school_settings.access_restriction.yes"
                )}
                onSuccess={() => {
                  handleSilentNotifications();
                }}
                onDecline={() => setIsSNModalOpen(false)}
              />
            </Modal>
            <SilentNotifications {...props} lifter={setSNPayload} />
            <SaveButton
              handleSubmitWithRedirect={() => setIsSNModalOpen(true)}
              submitOnEnter={true}
              disabled={isSnError}
              className={styles.saveBtns}
            />
          </div>
        </FormTab>
        <FormTab
          label={translate("resources.roles_permissions.set_permissions")}
        >
          <div className={styles.permissionInfoWrapper}>
            {showPermissionInfo && (
              <InfoCard
                text={translate("synapseadmin.users.permissions_info")}
              />
            )}
          </div>
          <h2 className={styles.title}>
            {translate("resources.roles_permissions.permissions")}
          </h2>
          {isLoading && (
            <Loading
              loadingPrimary=""
              loadingSecondary=""
              className={styles.center}
            />
          )}
          <Modal
            fullWidth
            isOpen={isPermissionModalOpen}
            onClose={() => {
              setIsPermissionModalOpen(false);
            }}
          >
            <CustomBooleanModal
              title={translate(
                "resources.school_settings.access_restriction.attention"
              )}
              text={translate("resources.roles_permissions.warning_msg")}
              declineBtn={translate(
                "resources.school_settings.access_restriction.no"
              )}
              successBtn={translate(
                "resources.school_settings.access_restriction.yes"
              )}
              onSuccess={() => {
                handlePermissions();
              }}
              onDecline={() => setIsPermissionModalOpen(false)}
            />
          </Modal>
          <SwitchGroup
            className={isLoading ? styles.blurred : styles.visible}
            bulkLabel={translate("resources.roles_permissions.toggle_all")}
            break={true}
            initialSwitchState={permissionsInit()}
            onValueChange={handleGroupPermissions}
          />
          <SaveButton
            handleSubmitWithRedirect={() => setIsPermissionModalOpen(true)}
            submitOnEnter={true}
            disabled={false}
            className={styles.saveBtns}
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default Settings;
